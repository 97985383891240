<template></template>

<script>
import config from "/config";

export default {
  async asyncData({ error, app, params, query, redirect }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}articles/${params.slug}`
      );
      if (response.status === 301) {
        redirect(
          301,
          query.ref
            ? `/read/${params.channel}/${response.redirect}?ref=${query.ref}`
            : `/read/${params.channel}/${response.redirect}`
        );
      } else {
        redirect(
          301,
          query.ref
            ? `/read/${params.channel}/${params.slug}?ref=${query.ref}`
            : `/read/${params.channel}/${params.slug}`
        );
      }
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
};
</script>
