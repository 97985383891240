var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"terpopuler-page"},[_c('Breadcrumbs',{attrs:{"background":"#f1f1f1"}}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('section',{staticClass:"list-terpopuler"},[_c('div',{staticClass:"container list-card-terpopuler"},[_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{directives:[{name:"show",rawName:"v-show",value:(_vm.terpopuler.loading),expression:"terpopuler.loading"}],key:index})}),_vm._v(" "),_vm._l((_vm.terpopuler.items),function(item){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.terpopuler.loading),expression:"!terpopuler.loading"}],key:item.id,attrs:{"image":item.thumbnail && item.thumbnail.large
            ? item.thumbnail.large
            : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '00:00',"channel":item.channel.title,"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"to":item.isText
            ? {
                name: `read-channel-slug`,
                params: {
                  channel: item.channel.slug,
                  slug: item.slug,
                },
              }
            : {
                name: 'video-channel-slug',
                params: {
                  channel: item.channel.slug,
                  slug: item.slug,
                },
              }}})})],2),_vm._v(" "),(_vm.terpopuler.items.length > 0 && _vm.terpopuler.pagination.pages > 1)?_c('div',{staticClass:"pagination-cards"},[(
          _vm.terpopuler.pagination &&
          _vm.terpopuler.pagination.pages &&
          _vm.terpopuler.pagination.pages > 1
        )?_c('paginate',{attrs:{"page-count":_vm.terpopuler.pagination.pages,"page-range":3,"margin-pages":1,"prev-class":"arrow","next-class":"arrow","click-handler":_vm.clickCallback,"container-class":'pagination',"page-class":'page-item'},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('template',{slot:"prevContent"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#757575"}})],1),_vm._v(" "),_c('template',{slot:"nextContent"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#757575"}})],1)],2):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"headline"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"headline__title"},[_vm._v("Artikel Terpopuler")]),_vm._v(" "),_c('p',{staticClass:"headline__desc"},[_vm._v("Kumpulan artikel terpopuler di Narasi TV")])])])
}]

export { render, staticRenderFns }